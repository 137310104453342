//react
import React from "react";

import { SvgIcon } from "@material-ui/core";
import { ReactComponent as InventorySVG } from "../Icons/InventoryIcon.svg";
import { ReactComponent as CRMSVG } from "../Icons/CRMIcon.svg";
import { ReactComponent as FilterSVG } from "../Icons/FilterIcon.svg";
import { ReactComponent as EditSVG } from "../Icons/EditIcon.svg";
import { ReactComponent as DownloadSVG } from "../Icons/DownloadIcon.svg";
import { ReactComponent as DeleteSVG } from "../Icons/DeleteIcon.svg";
import { ReactComponent as RefreshSVG} from "../Icons/refresh.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SaveIcon from "@material-ui/icons/Save";
import BackspaceIcon from "@material-ui/icons/Backspace";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { ReactComponent as DeleteCircleSVG } from "../Icons/DeleteCircleIcon.svg";
import { ReactComponent as CloseSVG } from "../Icons/CloseIcon.svg";
import { ReactComponent as MoreSVG } from "../Icons/MoreIcon.svg";

export function InventoryIcon(props) {
  return (
    <SvgIcon {...props}>
      <InventorySVG />
    </SvgIcon>
  );
}

export function CRMIcon(props) {
  return (
    <SvgIcon {...props}>
      <CRMSVG />
    </SvgIcon>
  );
}

export function FilterIcon(props) {
  return (
    <SvgIcon {...props}>
      <FilterSVG />
    </SvgIcon>
  );
}

export function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <EditSVG />
    </SvgIcon>
  );
}

export function DownloadIcon(props) {
  return (
    <SvgIcon {...props}>
      <DownloadSVG />
    </SvgIcon>
  );
}

export function DownloadIconDisabled(props) {
  return (
    <SvgIcon color={"disabled"} {...props}>
      <DownloadSVG />
    </SvgIcon>
  );
}

export function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <DeleteSVG />
    </SvgIcon>
  );
}
export function RefreshIcon(props) {
  return (
    <SvgIcon {...props}>
      <RefreshSVG />
    </SvgIcon>
  );
}
export function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <CloseSVG />
    </SvgIcon>
  );
}

export function MoreIcon(props) {
  return (
    <SvgIcon {...props}>
      <MoreSVG />
    </SvgIcon>
  );
}

export function ViewIcon(props) {
  return (
    <SvgIcon htmlColor={"#44C4A1"} {...props}>
      <VisibilityIcon />
    </SvgIcon>
  );
}

export function ViewIconDisabled(props) {
  return (
    <SvgIcon color={"disabled"} {...props}>
      <VisibilityIcon />
    </SvgIcon>
  );
}

export function SaveRowIcon(props) {
  return (
    <SvgIcon htmlColor={"#f9b571"} {...props}>
      <SaveIcon />
    </SvgIcon>
  );
}

export function CancelIcon(props) {
  return (
    <SvgIcon htmlColor={"#a2a2c3"} {...props}>
      <BackspaceIcon />
    </SvgIcon>
  );
}

export function CheckedIcon(props) {
  return (
    <SvgIcon htmlColor={"#44C4A1"} {...props}>
      <CheckBoxIcon />
    </SvgIcon>
  );
}

export function UncheckIcon(props) {
  return (
    <SvgIcon htmlColor={"#44C4A1"} {...props}>
      <CheckBoxOutlineBlankIcon />
    </SvgIcon>
  );
}
export function DeleteCircleIcon(props) {
  return (
    <SvgIcon {...props}>
      <DeleteCircleSVG />
    </SvgIcon>
  );
}
