import React, { Component } from "react";
import { messages, constants } from "./../../messages";
import Button from "./../../Shared/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TextField from "./../../Shared/TextField/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "./../../Shared/Select";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import TF from "@material-ui/core/TextField";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Switch from "@material-ui/core/Switch";
import AutoCompleteWithSearch from "./../AutoCompleteWithSearch";
import { getRole, getUserName, getUserId } from "../../helper";

class CommonFilter extends Component {
  format = constants.dateFormat;
  filterData = {};
  state = { reset: true };
  constructor(props) {
    super(props);
    this.filterData = props.filterData;
  }
  render() {
    return (
      <ClickAwayListener onClickAway={this.props.close}>
        {this.renderFilter()}
      </ClickAwayListener>
    );
  }
  renderHeader() {
    return (
      <div className="filter-heading">
        <span className="filter-title">Filter</span>
        <IconButton
          aria-label="back"
          onClick={this.props.close}
          className="close-icon"
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  }
  renderTextField(name, fieldname, maxlength, validation) {
    return (
      <div className="filter-item">
        <TextField
          name={fieldname}
          placeholder={name}
          onChange={(e) => {
            this.filterData[fieldname] = e;
          }}
          defaultValue={this.filterData[fieldname]}
          validation={validation}
          lengthConstraint={maxlength}
        />
      </div>
    );
  }
  renderFilterDate(label, name, seDisable = true, disabled = false) {
    const value = this.state[name] || this.filterData[name] || null;
    return (
      <KeyboardDatePicker
        autoOk
        disabled={disabled}
        inputVariant="outlined"
        format={this.format}
        clearable={true}
        InputAdornmentProps={{ position: "start" }}
        onBlur={(e) => {
          const value = e.target.value;
          let date = moment(value);
          if (!date._isValid) {
            this.setState({ [name]: undefined });
            this.filterData[name] = undefined;
          }
        }}
        onChange={(e) => {
          if (e) {
            if (e._isValid) {
              this.filterData[name] = moment(
                e._d,
                constants.USDateFormat
              ).format(this.format);
            } else {
              this.filterData[name] = undefined;
            }

            if (seDisable) {
              const startdate = this.filterData["startDate"];
              const enddate =
                this.filterData["endDate"] || this.filterData["EndDate"];
              const starttime = startdate && new Date(startdate).getTime();
              const endtime = enddate && new Date(enddate).getTime();

              if (startdate && enddate && starttime > endtime) {
                this.bdisable = true;
                this.disablemsg =
                  "Start Date should not be greater than End Date";
              } else {
                this.bdisable = false;
                this.disablemsg = undefined;
              }
            }
            this.setState({
              [name]: e._isValid
                ? moment(e._d, constants.USDateFormat).format(this.format)
                : undefined,
            });
          } else {
            this.setState({ [name]: undefined });
            this.filterData[name] = undefined;
          }
        }}
        //emptyLabel={messages.fields.date}
        label={label}
        margin="normal"
        value={value}
        inputValue={value}
      />
    );
  }
  renderAutoCompleteWithSearch(
    name,
    url,
    fieldname,
    getoption,
    multiple = true,
    disabled = false
  ) {
    return (
      <div className="filter-item">
        <AutoCompleteWithSearch
          url={url}
          onChange={(event, values) => (this.filterData[fieldname] = values)}
          defaultValue={this.filterData[fieldname]}
          name={name}
        />
      </div>
    );
  }
  renderAutoComplete(
    name,
    options = [],
    fieldname,
    getoption,
    multiple = true,
    disabled = false
  ) {
    return (
      <div className="filter-item">
        <Autocomplete
          multiple={multiple}
          id="tags-standard"
          options={options}
          disabled={disabled}
          onChange={(event, values) => {
            this.filterData[fieldname] = values;
            if (this.onFilterChange) {
              this.onFilterChange();
            }
          }}
          defaultValue={this.filterData[fieldname]}
          getOptionLabel={getoption}
          filterSelectedOptions={true}
          renderInput={(params) => (
            <TF
              {...params}
              name={name}
              id={fieldname}
              variant="outlined"
              margin="normal"
              label={name}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </div>
    );
  }
  renderSelect(label, options, fieldname) {
    return (
      <div className="filter-item">
        <Select
          multiple
          name={fieldname}
          options={options}
          onChange={(event) => {
            this.filterData[fieldname] = event.target.value;
          }}
          defaultValue={this.filterData[fieldname]}
        />
      </div>
    );
  }

  renderSwitch(label, fieldname, disabled = false) {
    return (
      <div className="filter-item">
        <div
          class="MuiAutocomplete-root MuiAutocomplete-hasClearIcon MuiAutocomplete-hasPopupIcon"
          role="combobox"
          aria-expanded="false"
        >
          <div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
            <label
              class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined"
              data-shrink="true"
              for={fieldname}
              id={fieldname + "-label"}
            >
              {label}
            </label>
            <div class="MuiInputBase-root MuiOutlinedInput-root MuiAutocomplete-inputRoot MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd">
              <Switch
                name={fieldname}
                disabled={disabled}
                checked={this.filterData[fieldname]}
                onChange={(event) => {
                  this.filterData[fieldname] = event.target.checked;
                }}
                color="default"
                inputProps={{ "aria-label": "checkbox with default color" }}
              />
              <fieldset
                aria-hidden="true"
                class="PrivateNotchedOutline-root-206 MuiOutlinedInput-notchedOutline"
              >
                <legend class="PrivateNotchedOutline-legendLabelled-208 PrivateNotchedOutline-legendNotched-209">
                  <span>{label}</span>
                </legend>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderFooter(isAssigneeDefault = false, buttonLabel = messages.common.apply, isDisabled = false, onReset = () => {}) {
    return (
      <div className="filter-bottom">
        <Button
          onClick={() => {
            this.filterData = {};
            if (isAssigneeDefault) {
              const currentUserRole = getRole();
              const currentUserId = getUserId();
              const currentUserName = getUserName();
              if (
                !(currentUserRole.toLowerCase().indexOf("admin") > -1) &&
                !(currentUserRole.toLowerCase().indexOf("crm-manager") > -1)
              ) {
                this.filterData = {
                  ...this.filterData,
                  assignee: [
                    { name: currentUserName, value: Number(currentUserId) },
                  ],
                };
              }
            }
            this.setState({ reset: false }, () =>
              this.setState({ reset: true })
            );
            onReset();
          }}
          buttonClass="grey"
          label={messages.common.reset}
        />
        <div title={this.disablemsg}>
          <Button
            onClick={() => {
              this.props.search(this.filterData);
              this.props.close();
            }}
            disabled={this.bdisable || isDisabled}
            buttonClass="blue"
            label={buttonLabel}
          />
        </div>
      </div>
    );
  }
}

export default CommonFilter;
