import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <a 
          href="https://www.nexus360.in" 
          target="_blank" 
          rel="noopener noreferrer"
          className="footer-link"
        >
          © {new Date().getFullYear()} Nexus360
        </a>
      </div>
    </footer>
  );
};

export default Footer;