import React, { Component } from "react";
import TF from "@material-ui/core/TextField";

class TextField extends Component {
  state = { value: undefined, error: false };
  componentDidMount() {
    if (this.props.compRef) {
      this.props.compRef(this);
    }
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }
  onChange(e, val) {
    const value = val !== undefined ? val : e.target.value;

    if (this.props.validation) {
      if (this.props.validation === "length") {
        if (value.length && value.length !== this.props.lengthConstraint) {
          if (this.state.error !== true) {
            this.props.inValidateForm(true);
            this.setState({ error: true });
          }
        } else {
          this.props.inValidateForm(false);
          this.setState({ error: false });
        }
      }
      if (this.props.validation === "maxlength") {
        if (value.length && value.length > this.props.lengthConstraint) {
          return false;
        }
      }

      if (this.props.validation === "nonegative") {
        if (Number(value) < 0) {
          this.setState({ value: "" });
          return;
        }
      }
      if (this.props.validation === "positive") {
        if (Number(value) <= 0) {
          this.setState({ value: "" });
          return;
        }
      }
      if (this.props.validation === "regex" && this.props.regexConstraint) {
        if (value.length && !this.props.regexConstraint.test(value)) {
          if (this.state.error !== true) {
            this.props.inValidateForm(true);
            this.setState({ error: true });
          }
        } else {
          this.props.inValidateForm(false);
          this.setState({ error: false });
        }
      }
    }
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }
  keyPress(event) {
    var key = event.key ? event.key : "";
    if (
      this.props.validation === "nonegative" ||
      this.props.validation === "positive"
    ) {
      if (key === "e" || key === "-") {
        event.preventDefault();
        //event.stopPropagation();
        return false;
      }
    }
  }
  setValue(value) {
    this.onChange(null, value);
  }
  render() {
    const props = this.props;
    const value = props.value !== undefined ? props.value : this.state.value;
    return (
      <TF
        className={props.className}
        variant={props.variant || "outlined"}
        margin="normal"
        required={props.required}
        fullWidth
        name={props.fieldname}
        InputProps={this.props.InputProps}
        InputLabelProps={{ shrink: true }}
        type={props.type}
        disabled={props.disabled}
        label={props.placeholder}
        onChange={(e) => this.onChange(e)}
        value={value}
        multiline={this.props.multiline}
        onKeyPress={this.props.validation ? (e) => this.keyPress(e) : null}
        helperText={this.state.error && this.props.errorMessage}
        error={this.state.error}
        placeholder={this.props.placeholderText}
      />
    );
  }
}

export default TextField;
