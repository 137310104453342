//react
import React from "react";
import { connect } from "react-redux";
//Third Party
import ListCommon from "./../../Shared/List";
import { withSnackbar } from "notistack";

//component
import Table from "./../../Shared/Table";
//misc
import { apiEndpoints, exportURL } from "./../../endpoints";
import { getCategories } from "./../../actions/categories";
import { messages } from "./../../messages";
import { API } from "./../../axios";
class List extends ListCommon {
  deleteKey = "productId";
  deleteUrl = apiEndpoints.individualProduct;
  title = messages.common.product;

  state = { data: [], options: [] };
  tableData = {
    headers: [
      messages.common.inventory,
      messages.common.description,
      "Reorder Quantity",
      messages.fields.measurementUnit,
      messages.fields.categoryName,
    ],
    keys: [
      "productName",
      "productDescription",
      "reorderQuantity",
      "measurementUnit",
      "category",
    ],
  };
  url = apiEndpoints.getProduct;
  exportUrl = exportURL.getProduct;
  exportFile = messages.exportFiles.product;
  componentDidMount() {
    this.search();
    this.getCategory();
  }
  prepareRequestBody() {
    let params;
    params = {};
    params.filterData = [];
    params.filterData.push({
      attrName: "name",
      attrValue: this.searchValue,
    });
    return params;
  }
  getExportData(response) {
    return response.data.content;
  }
  async getCategory() {
    const response = await API.GET(apiEndpoints.getCategoryNames);
    if (response.success) {
      this.props.getCategories(response.data);
    }
  }
  async search(page = 0) {
    const params = this.prepareRequestBody();
    const response = await this.getData(page, params);

    if (response.success) {
      let data = response.data.content;
      data = data.map((item) => {
        let category = item.category.categoryName;
        return { ...item, category: category };
      });
      this.setState({
        data: data,
        pages: response.data.totalPages,
        options: response.data.productAndCategoryNames || [],
      });
    }
  }

  render() {
    return (
      <div className="list-section">
        <div className="filter-section">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.search(0);
            }}
          >
            {this.renderAutoSearch(
              apiEndpoints.productGlobalSearch,
              "Search by Inventory Name or Category Name"
            )}
          </form>
          <div className="top-button-wrapper">{this.renderExport()}</div>
        </div>
        {this.state.isLoading ? (
          this.renderLoader()
        ) : (
          <Table
            tableData={this.tableData}
            rows={this.state.data}
            edit={this.props.edit}
            delete={(row) => this.delete(row)}
            sortby={this.sortby}
            sortkey={this.sortkey}
            search={(sortkey, sortby) => {
              this.sortby = sortby;
              this.sortkey = sortkey;
              this.search();
            }}
          />
        )}
        {this.renderPagination()}
      </div>
    );
  }
}
export default connect(null, { getCategories }, null, { forwardRef: true })(
  withSnackbar(List)
);
