import React, { Component } from "react";
import { API } from "./../../axios";
import { apiEndpoints, appRoutes } from "./../../endpoints";
import "./style.scss";
import Image1 from "./image1.png";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { setTenannt } from "./../../actions/tennant";
import { connect } from "react-redux";

class Project extends Component {
  projects = [];
  images = {
    kalpavrish: "/kalpavrish.png",
    riddhisiddhi: "/riddhisiddhi.jpg",
    suncitynx: "/suncitynx.jpg",
    drgtrdcntr: "/drgtrdcntr.png",
    smartcity: "/smartcity.jpg",
    businesspark: "/businesspark.jpg",
    egcity: "/eg-login-logo.png",
    citycenter: "/citycentre.jpg",
    school: "/school.jpg",
    bhaavbhumi:"/bhaavbhumi.jpg",
    dhabba:"/dhabba.jpg",
    mgrental:"/mgrental.jpeg"
  };
  async componentDidMount() {
    this.setState({ isLoading: true });
    if (this.props.allowedtenants.length === 0) {
      const response = await API.GET(apiEndpoints.getTenants);

      if (response.success) {
        this.projects = response.data;
      }
    } else {
      this.projects = this.props.allowedtenants;
    }
    this.setState({ isLoading: false });

    if (false && this.props.tennant && this.props.allowedtenants.length > 1) {
      this.setTennant(null);
    } else if (this.props.allowedtenants.length === 1) {
    }
  }
  setTennant(tenantCode) {
    this.props.setTenannt(tenantCode);

    localStorage.setItem("tennant-id", tenantCode);
    if (tenantCode) {
      this.props.history.push(
        appRoutes.dashboard + "?tennant-id=" + tenantCode
      );
    }
  }
  render() {
    return (
      <div className="project-page">
        <Grid container spacing={3}>
          {this.projects.map((project) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <Paper
                  className="project-wrapper"
                  onClick={() => {
                    this.setTennant(project.tenantCode);
                  }}
                >
                  <img
                    className="image"
                    src={
                      process.env.PUBLIC_URL + this.images[project.tenantCode]
                    }
                  />
                  <div className="title">{project.tenantName}</div>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tennant: state.tennant.tennant_id,
    allowedtenants: state.allTennant.allowedtenants,
  };
};
export default connect(mapStateToProps, { setTenannt }, null, {
  forwardRef: true,
})(Project);
