import axios from "axios";
import { getToken, removeToken } from "./helper";
import { store } from "./index";
export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
instance.interceptors.request.use(function (config) {
  const token = getToken();
  if (token) {
    const state = store.getState();
    config.headers.Authorization = `Bearer ${token}`;
    if (state.tennant.tennant_id) {
      config.headers["tenant-id"] = state.tennant.tennant_id;
    }
  }
  return config;
});
const checkToken = (error) => {
  if (error && error.response && error.response.status === 401) {
    removeToken();
    window.location.reload();
  }
};
export const API = {
  POST: async (url, params, config: {}) => {
    try {
      const response = await instance.post(url, params);

      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return {
        success: false,
        errorMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message,
      };
    }
  },
  PATCH: async (url, params) => {
    try {
      const response = await instance.patch(url, params);
      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return {
        success: false,
        errorMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message,
      };
    }
  },
  PUT: async (url, params) => {
    try {
      const response = await instance.put(url, params);
      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return {
        success: false,
        errorMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message,
      };
    }
  },
  GET: async (url, config) => {
    try {
      const response = await instance.get(url, config);
      if (response.config.responseType === "blob") {
        return response;
      }
      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return {
        success: false,
        errorMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message,
      };
    }
  },
  DELETE: async (url) => {
    try {
      const response = await instance.delete(url);
      return { data: response.data, success: true };
    } catch (error) {
      checkToken(error);
      return {
        success: false,
        errorMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message,
      };
    }
  },
};
