export const messages = {
  common: {
    reorderQuantity: "Reorder Quantity",
    stockStatus: "Stock Status",
    mrngrn: "MRN/GRN",
    receivingDate: "Receiving Date",
    purchaseOrderDate: "Purchase Order Date",
    purchaseOrderNo: "Purchase Order Number",
    buildingType: "Building Type",
    inventoryOutward: "Inventory Outward",
    returnInventory: "Return Product",
    total: "Total",
    action: "Action",
    add: "Add ",
    admin: "Admin",
    addSuccess: " added successfully",
    apply: "Apply",
    cancel: "Cancel",
    deleteSuccess: " deleted successfully",
    filter: "Filter",
    login: "Login",
    logout: "Logout",
    reset: "Reset",
    save: "Save",
    loginSubText: "Log in to continue to our website",
    loginSuccess: "Login Successful",
    loginFailure: "Invalid credentials",
    noRecords: "No Records Found",
    updateSuccess: " updated successfully",
    update: "Update ",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    passwordNotMatch: "New Password and Confirm Passwords don't match",
    passwordSuccess: "Password changed successfully",
    user: "User",
    searchByUsername: "Search by Username",
    contact: "Contact",
    searchByNameorNumber: "Search by Name or Number",
    searchByName: "Search by Name",
    warehouse: "Warehouse",
    inventory: "Inventory",
    category: "Category",
    product: "Product",
    machinery: "Machinery",
    location: "Building Unit",
    pricingHeader: "Pricing",
    pricingInventory: "Add Pricing",
    pricingReport: "Pricing Report",
    inwardInventory: "Inward",
    outwardInventory: "Outward",
    stock: "Stock",
    mor: "MOR",
    allInventory: "All Inventory",
    lost: "Lost",
    addProduct: "Add Inventory",
    zip: "ZIP / Postal Code",
    upload: "Upload",
    notification: "Notification",
    description: "Description",
    dashboard: "Dashboard",
    inventoryManagement: "Inventory",
    settings: "Settings",
    role: "Role",
    uploadedDocuments: "Uploaded Documents",
    details: "Details",
    vehicleNo: "Vehicle No.",
    ourSlipNo: "Our Slip No.",
    vendorSlipNo: "Vendor Slip No.",
    invoiceNo: "Invoice No.",
    additionalComment: "Additional Comments",
    unit: "Unit",
    quantity: "Quantity",
    oldquantity: "Old",
    returnedQauntity: "Returned",
    closingStock: "Closing Stock",
    contractor: "Contractor",
    slipNo: "Slip No.",
    purpose: "Purpose",
    usageLocation: "Usage Location",
    availableStock: "Available Stock",
    warehouseStock: "Warehouse Stock",
    noOfTrips: "No of Trips",
    amountCharged: "Amount Charged",
    initialMeterReading: "Initial Meter Reading",
    endMeterReading: "End Meter Reading",
    startDate: "Start Date",
    endDate: "End Date",
    comment: "Comment",
    mobileError: "Enter 10 characters",
    zipError: "Enter 6 characters",
    contactNameError: "Max 20 characters allowed",
    id: "ID",
    totalStock: "Total Stock",
    contactCompanyName: "Name / Company Name",
    finalLocation: "Final Location",
    max100: "Max 100 characters allowed",
    max20: "Max 20 characters allowed",
    export: "Download",
    exportMonthlyReport: "Monthly Report",
    crm: "CRM",
    broker: "Broker",
    sentiment: "Sentiment",
    source: "Source",
    leadActivity: "Lead/Activity",
    lead: "Lead",
    secondaryMobile: "Secondary Mobile",
    add1: "Address Line 1",
    add2: "Address Line 2",
    customerName: "Customer Name",
    occupation: "Occupation",
    dateOfBirth: "Date of Birth",
    propertyType: "Property Type",
    propertySubTypes: "Property Sub Type",
    assignee: "Assignee",
    pipelines: "PipeLines",
    planner: "Planner",
    projectPlan: "Project Plan",
    payments: "Payments",
    postsales: "Customers",
    aftersales: "After Sales",
    document: "Document",
    documentName: "Document Name",
    receivedStatus: "Received?",
    boq: "BOQ",
    boqStatus: "BOQ Status",
    boqQuantity: "BOQ Quantity",
    outwardQuantity: "Outward Quantity",
    plannerLabel: {
      week: "Week",
      call: "Call",
      meeting: "Meeting",
      propertyvisit: "Property Visit",
      dealclose: "Deal Close",
      reminder: "Reminder",
      task: "Payment",
      message: "Message",
      email: "Email",
      deallost: "Deal Lost",
    },
    pipelineLabel: {
      leadGeneration: "Lead Generation",
      visitScheduled: "Visit Scheduled",
      visitCompleted: "Visit Completed",
      negotiation: "Negotiation",
      dealClosure: "Deal Closure",
      stagnated: "Stagnated",
      days1020: "Days 10-20",
      days2030: "Days 20-30",
      days30more: "Days >30",
      sentimentOfCustomer: "Sentiment Of Customer",
      high: "HOT",
      medium: "MEDIUM",
      low: "COLD",
      pastActivity: "Past Activity",
      todayActivity: "Today's Activity",
      upcomingActivity: "Upcoming Activity",
      completed: "Completed",
    },
  },
  exportFiles: {
    allInventory: "All Inventory",
    category: "Category",
    contact: "Contact",
    stock: "Stock",
    BOQStatus:"BOQStatus",
    inwardInventory: "Inward Inventory",
    existingInventoryPricing: "Inventory Pricing (Existing)",
    missingInventoryPricing: "Inventory Pricing (Missing)",
    outwardInventory: "Outward Inventory",
    mor: "Machinery on Rent",
    lost: "Lost Inventory",
    user: "User",
    usageLocation: "Usage Location",
    finalLocation: "Final Location",
    product: "product",
    machinery: "Machinery",
    role: "Role",
    warehouse: "Warehouse",
    broker: "Broker",
    sentiment: "Sentiment",
    source: "Source",
    lead: "Lead",
    postSales: "Post Sales",
    buildingType: "Building Type",
  },
  fields: {
    address: "Address",
    email: "Email Address",
    password: "Password",
    role: "Role",
    name: "Supplier",
    contactName: "Contact Name",
    username: "Username",
    status: "Status",
    passwordExpired: "Password Expired",
    mobileNo: "Mobile Number",
    contactType: "Contact Type",
    email: "Email",
    city: "City",
    categoryName: "Category",
    buildingTypeName: "Building Type",
    productName: "Product name",
    measurementUnit: "Measurement Unit",
    machineryName: "Machinery",
    locationName: "Usage Area",
    usageArea: "Usage Area",
    date: "Date",
    machinery: "Machinery",
    supplier: "Supplier",
    location: "Location",
    mode: "Mode",
    usageAreaName: "Final Location",
    activityStatus: "Activity Status",
    owner: "Owner",
    activityType: "Activity Type",
    customerName: "Name",
    nextPaymentDate: "Next Payment Date",
    phoneNumber: "Phone Number",
    payments: {
      customerId: "Customer Id",
      customerName: "Customer Name",
      paymentDate: "Payment Date",
      amount: "Amount",
      isReceived: "Payment Received",
      propertyName: "Property Name",
      assignee: "Assignee",
      paymentFrom: "Payment From",
    },
  },
  formParams: {
    broker: {
      brokerPhoneno: "brokerPhoneno",
      brokerName: "brokerName",
      brokerAddress: "brokerAddress",
    },
    sentiment: {
      name: "name",
      description: "description",
    },
    source: {
      name: "sourceName",
      description: "sourceDescription",
    },
    lead: {
      customerName: "customerName",
      primaryMobile: "primaryMobile",
      secondaryMobile: "secondaryMobile",
      emailId: "emailId",
      purpose: "purpose",
      addressLine1: "addressLine1",
      addressLine2: "addressLine2",
      city: "city",
      pincode: "pincode",
      occupation: "occupation",
      dateOfBirth: "dateOfBirth",
      brokerId: "brokerId",
      sourceId: "sourceId",
      propertyType: "propertyType",
      sentimentId: "sentiment",
      assigneeId: "assigneeId",
      leadId: "leadId",
      status: "status",
    },
    documents: {
      documentId: "documentId",
      documentName: "documentName",
      receivedStatus: "receivedStatus",
      fileInformation: "fileInformation",
      fileUUId: "fileUUId",
      fileName: "fileName",
      upload: "upload",
    },
  },
};
export const constants = {
  adminDays: 2000,
  generalDays: 100,
  managerDays:100,

  monthFormat: "YYYY-MM",
  dateFormat: "DD-MM-YYYY",
  dateTimeFormat: "DD-MM-YYYY HH:mm",
  USDateFormat: "MM-DD-YYYY",
  USDate24Format: "MM-DD-YYYY HH:mm",
};
