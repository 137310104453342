import React, { Component } from "react";
import { connect } from "react-redux";

import "./style.scss";
class DebitNotePrint extends Component {
  rendermor() {
    const data = this.props.data;
    const mode = data.mode;
    let diffVal = "";
    let diffText = "";
    if (mode === "TripCount") {
      diffText = "No of Trips";
      diffVal = data.noOfTrips;
    } else if (mode === "Daily") {
      diffText = "DAYS DIFFERENCE";
      diffVal = new Date(data["endDate"]) - new Date(data["startDate"]);
      diffVal = diffVal / (24 * 3600 * 1000);
      diffVal = diffVal + 1;
    } else if (mode === "Hourly") {
      diffText = "TIME DIFFERENCE";
      diffVal = new Date(data["endDateTime"]) - new Date(data["startDateTime"]);
      diffVal = diffVal / (3600 * 1000);
    } else if (mode === "MeterReading") {
      diffText = "METER DIFFERENCE";
      diffVal = data["endMeterReading"] - data["initialMeterReading"];
    }
    return (
      <>
        <tr>
          <th className="no">SR. NO.</th>
          <th>{diffText}</th>
          <th className="notes">Additional Notes</th>

          <th className="rate">RATE</th>
          <th className="amount">AMOUNT</th>
          <th className="remarks">REMARKS</th>
        </tr>
        <tr>
          <td className="no">{1}</td>
          <td>{diffVal}</td>
          <td>{data.additionalNotes}</td>
          <td className="rate">{data.rate}</td>
          <td className="amount">{data.amountCharged}</td>
          <td className="remarks"></td>
        </tr>
      </>
    );
  }
  renderData() {
    let data = [];
    let dateKey = "rejectDate";
    let quantityKey = "rejectQuantity";
    if (this.props.inwardReject) {
      data = this.props.data.rejectInwardList;
    } else if (this.props.ourwardReject) {
      data = this.props.data.rejectOutwardList;
    } else if (this.props.outwardReturn) {
      data = this.props.data.returnOutwardList;
      dateKey = "returnDate";
      quantityKey = "returnQuantity";
    } else if (this.props.mor) {
      data = [];
    }
    return (
      <>
        <table className="top-table">{this.renderMahavirRow()}</table>
        <table>{this.renderAddRow()}</table>

        <table>
          {this.props.mor ? (
            this.rendermor()
          ) : (
            <React.Fragment>
              <tr>
                <th className="no">SR. NO.</th>
                <th className="date">DATE</th>
                <th className="desc">DESCRIPTION</th>
                <th className="quantity">QUANTITY</th>
                <th className="rate">RATE</th>
                <th className="amount">AMOUNT</th>
                <th className="remarks">REMARKS</th>
              </tr>
              {data.map((item, index) => (
                <tr>
                  <td className="no">{index + 1}</td>
                  <td className="date">{item[dateKey]}</td>
                  <td className="desc">{item.product.productName}</td>
                  <td className="quantity">{item[quantityKey]}</td>
                  <td className="rate"></td>
                  <td className="amount"></td>
                  <td className="remarks">{item.remarks}</td>
                </tr>
              ))}
            </React.Fragment>
          )}
          <tr>
            <td colSpan={this.props.mor ? "4" : "5"} className="align-right">
              TOTAL
            </td>
            <td className="amount"></td>
            <td className="remarks"></td>
          </tr>
          <tr>
            <td colSpan={this.props.mor ? "4" : "5"} className="align-right">
              Round off
            </td>
            <td className="amount"></td>
            <td className="remarks"></td>
          </tr>
          <tr>
            <td colSpan={this.props.mor ? "4" : "5"} className="align-right">
              NET AMOUNT
            </td>
            <td className="amount"></td>
            <td className="remarks"></td>
          </tr>
        </table>
      </>
    );
  }
  getAddress(client) {
    let address = "";
    if (client.city) {
      address += " " + client.city;
    }
    if (client.state) {
      address += " " + client.state;
    }
    if (client.zip) {
      address += " " + client.zip;
    }
  }

  renderMahavirRow() {
    let type = "";
    let selectenant = this.props.allTenant.filter(
      (t) => t.tenantCode === this.props.tennant
    );
    selectenant = selectenant[0] ? selectenant[0] : this.props.tennant;

    if (this.props.inwardReject) {
      type = "Inward Reject";
    } else if (this.props.ourwardReject) {
      type = "Debit Note";
    } else if (this.props.outwardReturn) {
      type = "Outward Return";
    } else if (this.props.mor) {
      type = "Machinery On Rent";
    }
    return (
      <>
        <tr>
          <td colSpan="7" className="middle">
            {process.env.REACT_APP_NAME}
          </td>
        </tr>

        <tr>
          <td colSpan="7" className="middle">
            {selectenant.tenantName}
          </td>
        </tr>
        <tr>
          <td colSpan="7" className="middle">
            {type}
          </td>
        </tr>
      </>
    );
  }
  renderAddRow() {
    let client = {},
      row1Text = "",
      row2Text = "",
      row3Text = "",
      row4Text = "",
      row5Text = "",
      row1Value = "",
      row2Value = "",
      row3Value = "",
      row4Value = "",
      row5Value = "";
    const data = this.props.data;
    if (this.props.inwardReject) {
      client = data.supplier;
      row1Text = "PO Date";
      row2Text = "PO Number";
      row3Text = "MRN Number";
      row4Text = "Receiving Date";
      row1Value = data.purchaseOrderdate;
      row2Value = data.purchaseOrder;
      row3Value = data.ourSlipNo;
      row4Value = data.date;
    } else if (this.props.ourwardReject || this.props.outwardReturn) {
      client = data.contractor;
      row1Text = "Outward date";
      row2Text = "Slip No";
      row3Text = "Building Unit";
      row4Text = "";
      row1Value = data.date;
      row3Value = data.usageLocation ? data.usageLocation.locationName : "";
      row2Value = data.slipNo;
      row4Value = "";
    } else if (this.props.mor) {
      client = data.contractor || {};
      row1Text = "Machinery";
      row2Text = "Vehicle No";
      row3Text = "Mode";
      row4Text = data.mode === "TripCount" ? "No of Trips" : "To ";
      row5Text = data.mode === "TripCount" ? "" : "From";
      row1Value = data.machinery.machineryName;
      row2Value = data.vehicleNo;
      row3Value = data.mode;
      row4Value =
        data.noOfTrips ||
        data.startDate ||
        data.startDateTime ||
        data.initialMeterReading;
      row5Value = data.endDate || data.endDateTime || data.endMeterReading;
    }
    const add = this.getAddress(client);
    return (
      <>
        <tr>
          <td className="width40">Contractor: {client.name}</td>
          <td className="width20"></td>
          <td className="width20">{row1Text}</td>
          <td className="width20">{row1Value}</td>
        </tr>
        <tr>
          <td className="width40">{client.addr_line1}</td>
          <td className="width20"></td>
          <td className="width20">{row2Text}</td>
          <td className="width20">{row2Value}</td>
        </tr>
        <tr>
          <td className="width40">{client.addr_line2}</td>
          <td className="width20"></td>
          <td className="width20">{row3Text}</td>
          <td className="width20">{row3Value}</td>
        </tr>
        <tr>
          <td className="width40">{add}</td>
          <td className="width20"></td>
          <td className="width20">{row4Text}</td>
          <td className="width20">{row4Value}</td>
        </tr>
        <tr>
          <td className="width40"></td>
          <td className="width20"></td>
          <td className="width20">{row5Text}</td>
          <td className="width20">{row5Value}</td>
        </tr>
      </>
    );
  }
  render() {
    const client = this.props.data.supplier || this.props.data.contractor || {};

    return (
      <div className="debit-note" id="debit-note">
        <div className="data">{this.renderData()}</div>
        <div className="footer">
          <div>
            AUTHORISED SIGNATURE <div>{process.env.REACT_APP_NAME}</div>
          </div>
          <div>STORE KEEPER</div>
          <div>Receiver</div>
          <div></div>
          <div className="footer-note4"></div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tennant: state.tennant.tennant_id,
    allTenant: state.allTennant.tennants,
  };
};
export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(DebitNotePrint);
