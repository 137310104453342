const noOfRecords = 12;
const exportPageSize = 2147483647;
export const apiEndpoints = {
  login: "/ec/login",
  userDetails: "/user/me",
  getRoles: "/role",
  createRole: "/role/create",
  getUsers: "/user?size=" + noOfRecords,
  createUser: "/user/create",
  getUserDetail: "/user/",
  updateUser: "/user/",
  getPayments: "/api/crm/customer/payments?size=" + noOfRecords,
  getPaymentsExport: "/api/crm/customer/payments?",
  getContact: "/api/inventory/contact?size=" + noOfRecords,
  createContact: "/api/inventory/contact/create",
  getContactDetail: "/api/inventory/contact/",
  updateContact: "/api/inventory/contact/",
  deleteContact: "/api/inventory/contact/",
  createWarehouse: "/api/inventory/warehouse/create",
  getWarehouse: "/api/inventory/warehouse/idandnames",
  updateWarehouse: "/api/inventory/warehouse/",
  getCategory: "/api/inventory/category?size=" + noOfRecords,
  createCategory: "/api/inventory/category/create",
  individualCategory: "/api/inventory/category/",
  getProduct: "/api/inventory/product?size=" + noOfRecords,
  createProduct: "/api/inventory/product/create",
  individualProduct: "/api/inventory/product/",
  getMachinery: "/api/inventory/machinery?size=" + noOfRecords,
  createMachinery: "/api/inventory/machinery/create",
  individualMachinery: "/api/inventory/machinery/",
  getLocation: "/api/inventory/location?size=" + noOfRecords,
  createLocation: "/api/inventory/location/create",
  individualLocation: "/api/inventory/location/",
  createInwardInventory: "/api/inventory/inward/create",
  getInwardInventory: "/api/inventory/inward?size=" + noOfRecords,
  getInwardInventoryDetail: "/api/inventory/inward/",
  getInwardInventoryTotals: "/api/inventory/inward/totals",
  updateInwardInventory: "/api/inventory/inward/",
  deleteInwardInventory: "/api/inventory/inward/",
  missingPrices: "/api/inventory/missingpricing?size=" + noOfRecords,
  exportMissingPrices: "/api/inventory/missingpricing?size=" + exportPageSize,
  getExistingPrices: "/api/inventory/inventorypricing?size=" + noOfRecords,
  exportExistingPrices: "/api/inventory/inventorypricing?size=" + exportPageSize,
  updatePrice: "/api/inventory/inventorypricing/create",
  deletePrice: "/api/inventory/inventorypricing/delete",
  pricingDropdowns: "/api/inventory/inventorypricing/dropdown",
  pricingReportLocationsDropdowns: "/api/inventory/inventoryusage/dropdown",
  pricingReportMonthsDropdowns: (locationId) => "/api/inventory/inventoryusage/" + locationId,
  pricingReportData: "/api/inventory/inventoryusage",
  getCurrentStock: "/api/inventory/stock/current?",
  getBoqQuantity: "/api/inventory/boqupload/getboqquantity?",
  getMultiStock: "/api/inventory/stock/current",
  getOutwardInventory: "/api/inventory/outward?size=" + noOfRecords,
  getOutwardInventoryTotals: "/api/inventory/outward/totals",
  createOutwardInventory: "/api/inventory/outward/create",
  individualOutwardInventory: "/api/inventory/outward/",
  getStock: "/api/inventory/stock?size=" + noOfRecords,
  getMOR: "/api/inventory/mor?size=" + noOfRecords,
  createMOR: "/api/inventory/mor/create",
  individualMOR: "/api/inventory/mor/",
  getAllInventoryTransactions: "/api/inventory/inventory?size=" + noOfRecords,
  refreshClosingStock:  "/api/inventory/inventory/refresh",
  getLost: "/api/inventory/lostdamaged?size=" + noOfRecords,
  createLost: "/api/inventory/lostdamaged/create",
  individualLost: "/api/inventory/lostdamaged/",
  createUsageArea: "/api/inventory/usagearea/create",
  individualUsageArea: "/api/inventory/usagearea/",
  getUsageArea: "/api/inventory/usagearea?size=" + noOfRecords,
  fileUpload: "/api/inventory/file/upload",
  download: "/api/inventory/file/download/",
  dashboard: "/api/inventory/dashboard",
  iostats: "/api/inventory/dashboard/v2/iostats",
  stockstats: "/api/inventory/dashboard/v2/stockstats",
  inwardstats: "/api/inventory/dashboard/v2/inwardstats",
  outwardstats: "/api/inventory/dashboard/v2/outwardstats",
  iotrend: "/api/inventory/dashboard/v2/iotrend",
  deleteNotification: "/api/inventory/dashboard/notification/",
  getNotification: "/api/inventory/dashboard/notification",
  getMeasurement: "/api/inventory/product/measurementunits/all",
  individualBroker: "/api/crm/broker/",
  createBroker: "/api/crm/broker/create",
  getBroker: "/api/crm/broker?size=" + noOfRecords,
  individualSentiment: "/api/crm/sentiment/",
  createSentiment: "/api/crm/sentiment/create",
  getSentiment: "/api/crm/sentiment?size=" + noOfRecords,
  individualSource: "/api/crm/source/",
  createSource: "/api/crm/source/create",
  getSource: "/api/crm/source?size=" + noOfRecords,
  individualLead: "/api/crm/lead/",
  createLead: "/api/crm/lead/create",
  importLead: "/api/crm/lead/import",
  getLead: "/api/crm/activity/getleadactivitypage?size=" + noOfRecords,
  getPurposeDropdownValues: "/api/crm/lead/purpose",
  getValidLeadStatus: "/api/crm/lead/validLeadStatus",
  getAllLeadInfo: "/api/crm/lead/getallinfo/",
  getPipeLineDetails: "/api/crm/pipeline/activities",
  getPlannerDetails: "api/crm/planner/activities",
  getstagnantDetailsDB: "api/crm/dashboard/stagnant",
  gettopPerformerDB: "api/crm/dashboard/topperformer",
  getSalesFunnel: "api/crm/dashboard/salesfunnel",
  getconversionRatioDB: "api/crm/dashboard/conversionratio",
  getleadstagemapping: "api/crm/dashboard/leadstagemapping",
  getpipelineDB: "api/crm/dashboard/customerpipeline",
  getActivitiesData: "api/crm/dashboard/activitystats",
  crmFileUpload: "/api/crm/file/upload",
  createNote: "/api/crm/notes/create",
  createActivity: "/api/crm/activity/create",
  getValidActivityStatus: "/api/crm/activity/validActivityTypes",
  rescheduleActivity: "/api/crm/activity/reschedule/",
  closeActivity: "/api/crm/activity/",
  unpinNote: "/api/crm/notes/",
  updateNote: "/api/crm/notes/",
  revertActivity: "/api/crm/activity/revert/",
  getpostSales: "/api/crm/customer?size=" + noOfRecords,
  getdropdowns: "/api/crm/customer/dropdown",
  getpaymentDropdowns: "/api/crm/customer/payments/dropdown",
  getCustomerBasicInfo: "/api/crm/customer/customerdetails/",
  getCustomerAllInfo: "/api/crm/customer/activities/",
  getStepperInfo: "/api/crm/customer/status/{leadId}",
  getAllowedActivityStatus: "/api/crm/activity/allowedactivitytype/",
  createPostSalesDocument: "/api/crm/customer/document/create",
  getPostSalesDocumentList: "/api/crm/customer/{leadId}/documents",
  getPostSalesDocument: "/api/crm/customer/documents/{documentId}",
  updatePostSalesDocument: "/api/crm/customer/documents/{documentId}",
  fileDownload: "/api/crm/file/download/{fileid}",
  properyTypes: "/api/crm/project/type/",
  properySubTypes: "/api/crm/project/property/",
  getDealStructure: "/api/crm/customer/dealstructure/bycustomer/{leadid}",
  getPropertyTypes: "/api/crm/customer/dealstructure/propertytypes",
  getPropertyName:
    "/api/crm/customer/dealstructure/{propertyTypeId}/properties",
  createDealStructure: "/api/crm/customer/dealstructure/create",
  updateDealStructure: "/api/crm/customer/dealstructure/{dealId}",
  deleteDealStructure: "/api/crm/customer/dealstructure/{dealId}",
  getSchedulesByDeal: "/api/crm/customer/paymentschedule/bydealid/{dealId}",
  deleteSchedule: "/api/crm/customer/paymentschedule/{scheduleId}",
  createSchedule: "/api/crm/customer/paymentschedule/create",
  updateSchedule: "/api/crm/customer/paymentschedule/{scheduleId}",
  getLeadDropdowns: "/api/crm/activity/getleadactivitypage/dropdown",
  locationTypeAhead: "/api/inventory/location/typeahead/",
  contactGlobalSearch: "/api/inventory/contact/typeahead/globalsearch/",
  contactNameSearch: "/api/inventory/contact/typeahead/namesearch/",
  productGlobalSearch: "/api/inventory/product/typeahead/",
  getTenants: "/user/allowedtenants",
  getCategoryNames: "/api/inventory/product/categorynames",
  getBuildingType: "/api/inventory/buildingtype?size=" + noOfRecords,
  createBuildingType: "/api/inventory/buildingtype/create",
  individualBuildingType: "/api/inventory/buildingtype/",
  getAllTennants: "/tenants",
  stockDropdown: "/api/inventory/stock/getfilterdropdown",
  buildingType: "/api/inventory/buildingtype/idandnames",
  outwardReject: "/api/inventory/outward/",
  inwardReject: "/api/inventory/inward/",
  changePassword: "/user/changepassword",
  boqTypes: "/api/inventory/boq/alltypes",
  buildingsUnderBOQ: "/api/inventory/boq/type/",
  boqInventoryType: "/api/inventory/boq/bytype/",
  boqInventoryUnit: "/api/inventory/boq/byunit/",
  boqTypewithCount: "/api/inventory/boqstatus/typelistwithcount",
  boqLocationList: "/api/inventory/boqstatus/locationlist/",
  boqStatusInventory: "/api/inventory/boqstatus/inventorylist/",
  getLocationNamesBOQ: "/api/inventory/boqstatus/inventorynames/",
  getInventoryNamesBOQ: "/api/inventory/boqstatus/inventorynames/",
  deleteBOQ: "/api/inventory/boq/",
  productListType: "/api/inventory/boq/getproductlist/",
  boqadd: "/api/inventory/boq/create",
  dealLostReasons: "/api/crm/activity/deallostreasons",
  getPaymentDropdownValues:
    "/api/crm/customer/payments/received/dropdown",
  savePaymentRecieved:
    "/api/crm/customer/payments/received",
  getPaymentListByDealId:
    "/api/crm/customer/payments/received/{dealId}/paymentlist",
  updatePaymentRecieved:
    "/api/crm/customer/payments/received",
  onDeletePayment:
    "/api/crm/customer/payments/received/{paymentId}",
  getPaymentStatus:
    "/api/crm/customer/paymentstatus/bydealid/{leadid}",

  BOQupload: "/api/inventory/boqupload/boq_upload",
  getBOQStatusDetails: "/api/inventory/boqupload/get_boq_status_details?size=" + noOfRecords,
  getBuildingUnit: "/api/inventory/boqupload/get_buildingunit_by_buildingtypeid/",
  getBOQReport: "/api/inventory/boqupload/get_boq_report",
};
export const exportURL = {
  getAllInventoryTransactions:
    "/api/inventory/inventory?size=" + exportPageSize,
  getInventoryReport:
    "/api/inventory/inventory/report",
  getCategory: "/api/inventory/category?size=" + exportPageSize,
  getExistingPrices: "/api/inventory/inventorypricing?size=" + exportPageSize,
  getContact: "/api/inventory/contact?size=" + exportPageSize,
  getStock: "/api/inventory/stock/export",
  getBOQStatus:"/api/inventory/boqupload/get_boq_status_details",
  getInwardInventory: "/api/inventory/inward/export",
  getOutwardInventory: "/api/inventory/outward/export",
  getMOR: "/api/inventory/mor/export?size=" + exportPageSize,
  getLost: "/api/inventory/lostdamaged?size=" + exportPageSize,
  getUsers: "/user?size=" + exportPageSize,
  getLocation: "/api/inventory/location?size=" + exportPageSize,
  getUsageArea: "/api/inventory/usagearea?size=" + exportPageSize,
  getProduct: "/api/inventory/product?size=" + exportPageSize,
  getMachinery: "/api/inventory/machinery?size=" + exportPageSize,
  getRoles: "/role",
  getWarehouse: "/api/inventory/warehouse/idandnames",
  getBroker: "/api/crm/broker?size=" + exportPageSize,
  getSentiment: "/api/crm/sentiment?size=" + exportPageSize,
  getSource: "/api/crm/source?size=" + exportPageSize,
  getLead: "/api/crm/activity/getleadactivitypage/export?size=" + exportPageSize,
  getCustomer: "/api/crm/customer?size=" + exportPageSize,
  getBuildingType: "/api/inventory/buildingtype?size=" + exportPageSize,
};
export const appRoutes = {
  dashboard: "/dashboard",
  project: "/project",
  login: "/login",
  home: "/",
  user: "/user",
  role: "/role",
  broker: "/crm/broker",
  sentiment: "/crm/sentiment",
  source: "/crm/source",
  lead: "/crm/lead",
  leadinfo: "/crm/leadinfo/:id",
  pipelines: "/crm/pipelines",
  planner: "/crm/planner",
  projectPlan: "/crm/project-plan",
  payments: "/crm/payments",
  postsales: "/crm/postsales",
  changePassword: "/change-password",
  crmDashboard: "/crm/dashboard",
  customerinfo: "/crm/customerinfo/:id",
  boq: "/boq",
  boqStatus: "/boqStatus",

};
