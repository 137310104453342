import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { ReactComponent as DownloadSVG } from "../Icons/DownloadIcon.svg"
import { ReactComponent as FilterSVG } from "../Icons/FilterIcon.svg"

import "./style.scss";
class IconButtons extends Component {
  render() {
    return (
      <Button
        color="primary"
        variant="contained"
        component={this.props.component}
        classes={{
          root: this.props.buttonClass,
          label: "button-label",
        }}
        onClick={this.props.onClick}
        ref={this.props.innerRef}
        type={this.props.type}
        disabled={this.props.disabled}
        startIcon={this.props.icon  === "DownloadSVG" ? <DownloadSVG /> : this.props.icon === "FilterSVG" ? <FilterSVG /> : null }
      >
       {this.props.label}
      </Button>
    );
  }
}

export default IconButtons;
