import React, { Component } from "react";
import { connect } from "react-redux";

import "./printstyle.scss";
class Print extends Component {
  renderData() {
    let data = [];
    data = this.props.data.inwardOutwardList;
    let data1 = [];
    data1 = this.props.data.rejectInwardList;

    let rejectQuantityMap = {};
    let rejectQuantityMap1 = {};
    let rejectQuantityMap2 = {};
    data1.forEach(item => {
    rejectQuantityMap[item.product.productId] = item.oldQuantity;
    rejectQuantityMap1[item.product.productId] = item.rejectQuantity;
    rejectQuantityMap2[item.product.productId] = item.remarks;
});

    return (
      <>
        <table className="top-table">{this.renderMahavirRow()}</table>
        <table>{this.renderAddRow()}</table>

        <table>
          <tr>
            <th className="no">SR. NO.</th>
            <th className="desc">DESCRIPTION</th>
            <th className="quantity">QUANTITY</th>
            <th className="uom">UOM</th>
            <th className="rate">Received Quantity</th>
            <th className="amount">Reject Quantity</th>
            <th className="remarks">REMARKS</th>
          </tr>
          {data.map((item, index) => (
            <tr>
              <td className="no">{index + 1}</td>
              <td className="desc">{item.product.productName}</td>
              <td className="quantity">{item.quantity}</td>
              <td className="uom">{item.product.measurementUnit}</td>
              <td className="rate">{rejectQuantityMap[item.product.productId] !== undefined ? rejectQuantityMap[item.product.productId] : ""}</td>
              <td className="amount">{rejectQuantityMap1[item.product.productId] !== undefined ? rejectQuantityMap1[item.product.productId] : ""}</td>
              <td className="remarks">{rejectQuantityMap2[item.product.productId] !== undefined ? rejectQuantityMap2[item.product.productId] : ""}</td>
            </tr>
          ))}
        </table>
      </>
    );
  }
  getAddress(client) {
    let address = "";
    if (client.city) {
      address += " " + client.city;
    }
    if (client.state) {
      address += " " + client.state;
    }
    if (client.zip) {
      address += " " + client.zip;
    }
  }

  renderMahavirRow() {
    let type = "";
    let selectenant = this.props.allTenant.filter(
      (t) => t.tenantCode === this.props.tennant
    );
    selectenant = selectenant[0] ? selectenant[0] : this.props.tennant;

    type = "Inward";
    return (
      <>
        <tr>
          <td colSpan="8" className="middle">
            {process.env.REACT_APP_NAME}
          </td>
        </tr>

        <tr>
          <td colSpan="8" className="middle">
            {selectenant.tenantName}
          </td>
        </tr>
        <tr>
          <td colSpan="8" className="middle">
            {type}
          </td>
        </tr>
      </>
    );
  }
  renderAddRow() {
    let client = {},
      row1Text = "",
      row2Text = "",
      row3Text = "",
      row4Text = "",
      row5Text = "",
      row1Value = "",
      row2Value = "",
      row3Value = "",
      row4Value = "",
      row5Value = "";
    const data = this.props.data;
    client = data.supplier;
    row1Text = "PO Date";
    row2Text = "PO Number";
    row3Text = "MRN Number";
    row4Text = "Receiving Date";
    row1Value = data.purchaseOrderdate;
    row2Value = data.purchaseOrder;
    row3Value = data.ourSlipNo;
    row4Value = data.date;

    const add = this.getAddress(client);
    return (
      <>
        <tr>
          <td>Id:</td>
          <td> {data.inwardid}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td className="width40">Supplier: </td>
          <td className="width20">{client.name}</td>
          <td className="width20">{row1Text}</td>
          <td className="width20">{row1Value}</td>
        </tr>
        <tr>
          <td className="width40">Vehicle No</td>
          <td className="width20">{data.vehicleNo}</td>
          <td className="width20">{row2Text}</td>
          <td className="width20">{row2Value}</td>
        </tr>
        <tr>
          <td className="width40">Invoice No</td>
          <td className="width20">{data.billNo}</td>
          <td className="width20">{row3Text}</td>
          <td className="width20">{row3Value}</td>
        </tr>
        <tr>
          <td className="width40">Invoice Date</td>
          <td className="width20">{data.billDate}</td>
          <td className="width20">{row4Text}</td>
          <td className="width20">{row4Value}</td>
        </tr>
        <tr>
          <td className="width40">Billing Name</td>
          <td className="width20"></td>
          <td className="width20">Transporter Name</td>
          <td className="width20"></td>
        </tr>
        <tr>
          <td className="width40">Location</td>
          <td className="width20">{data.warehouse.warehouseName}</td>
          <td className="width20"></td>
          <td className="width20"></td>
        </tr>
        <tr>
          <td className="width40"></td>
          <td className="width20"></td>
          <td className="width20">{row5Text}</td>
          <td className="width20">{row5Value}</td>
        </tr>
      </>
    );
  }
  render() {
    const client = this.props.data.supplier || this.props.data.contractor || {};

    return (
      <div className="debit-note" id="debit-note">
          <div className="logo-container">
        <img src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_LOGIN_LOGO}.png`} alt="Logo" className="logo" />
      </div>
        <div className="data">{this.renderData()}</div>
        <div className="footer">
          <div>
            AUTHORISED SIGNATURE <div>{process.env.REACT_APP_NAME}</div>
          </div>
          <div>STORE KEEPER</div>
          <div>Receiver</div>
          <div></div>
          <div className="footer-note4"></div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tennant: state.tennant.tennant_id,
    allTenant: state.allTennant.tennants,
  };
};
export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(Print);
