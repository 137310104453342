import Cookies from "js-cookie";
import moment from "moment";
import { constants } from "./messages";
const mainKey = process.env.REACT_APP_COOKIES_MAIN_KEY;
const tokenKey = `${mainKey}Token`;
const expiryTime = 0.5;
const roleKey = `${mainKey}Role`;
const userIdKey = `${mainKey}"UserId`;
const userKey = `${mainKey}ecUserName`;
let _isAdmin;

export const checkifDateLessThan = (date, days) => {
  const dateo = new Date(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")).getTime();
  const today = new Date().getTime();
  const diff = days * 24 * 60 * 60 * 1000;
  return today - dateo > diff;
};
export const getRoleEditConstraintDays = () => {
  const role = getRole();
  var days = 30;

  if(role.indexOf("admin") > -1 ){
    days = constants.adminDays;
  }
  else if(role.indexOf("inventory-manager") > -1 ){
    days = constants.managerDays;
  }
  else{
    days = constants.generalDays
  }
  return days;
};
export const getToken = () => {
  return Cookies.get(tokenKey);
};
export const removeToken = () => {
  Cookies.remove(tokenKey, { expires: expiryTime });
};

export const setToken = (token) => {
  Cookies.set(tokenKey, token, {
    expires: expiryTime,
  });
};

export const setUserId = (userId) => {
  Cookies.set(userIdKey, userId);
};

export const getUserId = () => {
  return Cookies.get(userIdKey);
};

export const setRole = (role) => {
  Cookies.set(roleKey, role);
};
export const getRole = () => {
  return Cookies.get(roleKey);
};
export const setUserName = (role) => {
  Cookies.set(userKey, role);
};
export const getUserName = () => {
  return Cookies.get(userKey);
};

export const clearCookies = () => {
  Cookies.remove(userKey);
  Cookies.remove(roleKey);
  Cookies.remove(userIdKey);
  Cookies.remove(tokenKey);
}
export const isAdmin = () => {
  if (!_isAdmin) {
    const role = getRole();
    _isAdmin = role ? role.indexOf("admin") > -1 : false;
  }
  return _isAdmin;
};
export const stringNotNull = (str) => {
  return str !== null ? str : "";
};

export const setSession = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSession = (key) => {
  if (
    sessionStorage.getItem(key) !== undefined &&
    sessionStorage.getItem(key) !== null
  ) {
    return JSON.parse(sessionStorage.getItem(key));
  } else {
    return null;
  }
};

export const clearSession = (key) => {
  sessionStorage.removeItem(key);
};

export const clearAllSession = () => {
  sessionStorage.clear();
};
export const getDiffInDays = (fromdate) => {
  return moment().diff(fromdate, "days");
};

export const getDiffInDaysv2 = (fromdate) => {
  // Set both dates to the start of their respective days
  const startOfToday = moment().startOf('day');
  const startOfFromDate = moment(fromdate).startOf('day');

  // Calculate the difference in days
  return startOfToday.diff(startOfFromDate, 'days');
};

export const getFormatedDateTime = (date) => {
  return moment(date, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY hh:mm:ss A");
};

export const getJson2csvCallback = (exportedFilename) => {
  let json2csvCallback = (err, csv) => {
    if (err) throw err;

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  return json2csvCallback;
}