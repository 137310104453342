import React, { Component } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "./../../Shared/Button";
class DeleteConfirm extends Component {
  render() {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={this.props.open}
      >
        <DialogTitle id="confirmation-dialog-title">Confirm</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete this record?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.onCancel}
            buttonClass="grey"
            label="Cancel"
          ></Button>
          <Button
            onClick={this.props.onConfirm}
            buttonClass="blue"
            label="Confirm"
          ></Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeleteConfirm;
