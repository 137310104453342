//react
import React from "react";
import { connect } from "react-redux";

//third party
import { withSnackbar } from "notistack";
import AddForm from "./../../Shared/AddForm";
import { API } from "./../../axios";

//misc
import { apiEndpoints } from "./../../endpoints";
import { messages } from "./../../messages";
import Button from "./../../Shared/Button";
import DeleteIcon from "@material-ui/icons/Delete";
//style
import "./style.scss";
import { fetchUnit } from "./../../actions/measurementUnit";
//misc
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { getRoleEditConstraintDays } from "./../../helper";
class Add extends AddForm {
  title = messages.common.inventory;
  addurl = apiEndpoints.createInwardInventory;
  state = {
    value: 0,
    noproduct: {},
    currentStock: {},
  };
  key = 1;
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchUnit());
  }
  renderProduct(key) {
    const currentProductId = this.state.noproduct?.[key]?.productId;
    const selectedProducts = Object.keys(this.state.noproduct).map(index => this?.state?.noproduct?.[index]?.productId);
    const remainingProducts = (this.props.dropdowns?.product??[]).filter(item => (!selectedProducts.includes(item.id) || currentProductId === item.id));
    return (
      <div className="flex" key={key}>
        {this.renderAutoComplete({
          fieldname: "productId",
          placeholder: messages.common.inventory,
          options: remainingProducts,
          disableClearable: true,
          required: true,
          getOption: (option) => {
            return option["name"];
          },
          onChange: (e, value) => {
            const p = this.state.noproduct;
            p[key].productId = value.id || "";
            if (value) {
              this.getCurrentStock(key);
            }
          },
        })}
        {this.renderTextField({
          fieldname: "measurementUnit",
          placeholder: "Measurement Unit",
          disabled: true,
          value: this.props.units[this.state.noproduct[key].productId],
        })}
        {this.renderTextField({
          fieldname: "quantity",
          placeholder: "Quantity",
          type: "number",
          required: true,
          skipAdd: true,
          validation: "nonegative",
          onChange: (value) => {
            const p = this.state.noproduct;
            p[key].quantity = value;
            this.getCurrentStock(key);
          },
        })}

        {this.renderTextField({
          fieldname: "ClosingStock",
          placeholder: "Closing Stock",
          type: "number",
          disabled: true,
          value: this.state.currentStock[this.state.noproduct[key].productId],
        })}
        <IconButton
          aria-label="back"
          onClick={() => {
            const p = this.state.noproduct;
            delete p[key];
            this.setState({ noproduct: { ...p } });
          }}
          className="back-icon"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }
  async getCurrentStock(index) {
    const warehouseId = this.formData.warehouseId;
    const productId = this.state.noproduct[index].productId;
    if (!productId) {
      return;
    }
    const response = await API.GET(
      apiEndpoints.getCurrentStock +
        "productId=" +
        productId +
        "&warehouseId=" +
        warehouseId
    );
    if (response.success) {
      const currentStock = this.state.currentStock;
      const productId = this.state.noproduct[index].productId;
      currentStock[productId] =
        Number(response.data) + Number(this.state.noproduct[index].quantity);
      this.setState({ currentStock: { ...currentStock } });
    }
  }
  async add(event) {
    event.preventDefault();

    if (Object.keys(this.state.noproduct).length === 0) {
      this.props.enqueueSnackbar("Add atleast one Product", {
        variant: "error",
      });
      return;
    }
    if (
      !this.formData.fileInformations ||
      this.formData.fileInformations.length === 0
    ) {
      this.props.enqueueSnackbar("Add atleast one file", {
        variant: "error",
      });
      return;
    }

    const params = this.formData;
    this.setState({ isAdding: true });

    params.productWithQuantities = Object.values(this.state.noproduct);
    const response = await API.POST(this.addurl, params);
    this.showToaster(response);
    this.setState({ isAdding: false });
  }
  async updateStockInfo(id) {
    const params = {};
    params.warehouseId = id;
    params.productIds = Object.values(this.state.noproduct).map(
      (p) => p.productId
    );
    const response = await API.POST(apiEndpoints.getMultiStock, params);
    if (response.success) {
      const data = response.data;
      const currentStock = {};
      const products = Object.values(this.state.noproduct);
      data.forEach((element) => {
        const productId = element.productId;
        let product = products.filter((p) => p.productId === productId);
        product = product[0];
        currentStock[productId] = element.stock + Number(product.quantity);
      });
      this.setState({ currentStock: currentStock });
    }
  }
  renderFooter() {
    return (
      <div className="form-footer">
        {this.renderToggle("Invoice Received", "invoiceReceived")}
        <Button
          onClick={this.props.back}
          buttonClass="grey"
          label={messages.common.cancel}
        />
        <Button
          type="submit"
          buttonClass="blue"
          label={messages.common.save}
          disabled={
            this.state.isAdding || Object.keys(this.formValidation).length
          }
        />
      </div>
    );
  }
  render() {
    const days = getRoleEditConstraintDays();

    return (
      <div className="list-section add">
        {this.renderHeading()}

        <form onSubmit={(e) => this.add(e)}>
          <div className="flex width50">
            {this.renderDate({
              fieldname: "date",
              label: messages.common.receivingDate,
              maxDate: moment(),
              minDate: moment().add(-days, "d"),
            })}
            {this.renderAutoComplete({
              fieldname: "warehouseId",
              placeholder: "Warehouse",
              options: this.props.dropdowns?.warehouse,
              disableClearable: true,
              required: true,
              getOption: (option) => {
                return option["name"];
              },
              onChange: (e, value) => {
                if (value) {
                  this.formData.warehouseId = value.id;
                  this.updateStockInfo(value.id);
                }
              },
            })}
          </div>
          <div className="flex width30">
            {this.renderAutoComplete({
              fieldname: "supplierId",
              placeholder: "Supplier",
              options: this.props.dropdowns?.supplier,
              disableClearable: true,
              required: true,
              getOption: (option) => {
                return option["name"];
              },
              onChange: (e, value) => {
                if (value) {
                  this.formData.supplierId = value.id;
                }
              },
            })}
            {this.renderDate({
              fieldname: "purchaseOrderDate",
              label: "Purchase Order Date",
            })}
            {this.renderTextField({
              fieldname: "purchaseOrder",
              placeholder: "Purchase Order No",
              validation: "maxlength",
              lengthConstraint: 30,
              errorMessage: messages.common.contactNameError,
            })}
          </div>
          {/* <div className="flex width50">
            {this.renderTextField({
              fieldname: "vendorSlipNo",
              placeholder: "Vendor Slip No",
              validation: "maxlength",
              lengthConstraint: 100,
              errorMessage: messages.common.max100,
            })}
          </div> */}
          <div className="width50 flex">
            {this.renderTextField({
              fieldname: "ourSlipNo",
              placeholder: "MRN / GRN",
              validation: "maxlength",
              lengthConstraint: 100,
              errorMessage: messages.common.max100,
            })}
            {this.renderTextField({
              fieldname: "vehicleNo",
              placeholder: "Vehicle No",
              validation: "maxlength",
              lengthConstraint: 100,
              errorMessage: messages.common.max100,
            })}
          </div>
          <div className="flex width50">
            {this.renderDate({
              fieldname: "billDate",
              label: "Bill Date",
              emptyDate: true,
              type: "date",
            })}
            {this.renderTextField({
              fieldname: "billNo",
              placeholder: "Bill no",
            })}
          </div>

          <div className="flex width50">
            {this.renderDate({
              fieldname: "challanDate",
              label: "Challan Date",
              emptyDate: true,
              type: "date",
            })}
            {this.renderTextField({
              fieldname: "challanNo",
              placeholder: "Challan No",
            })}
          </div>
          <div class="flex">
            {this.renderTextArea({
              fieldname: "additionalInfo",
              placeholder: "Additional Comments",
            })}
          </div>
          {this.renderFileArea()}
          <div className="products-list">
            <div className="product-heading">
              <div>Products</div>
            </div>
            {Object.keys(this.state.noproduct).map((key) =>
              this.renderProduct(key)
            )}
            {this.renderProductAddButton()}
          </div>

          {this.renderFooter()}
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    units: state.units.units,
  };
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withSnackbar(Add)
);
