import React from "react";
import CommonDetails from "./../../Shared/Details";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { messages } from "./../../messages";
import {
  EditIcon,
  DeleteIcon,
  MoreIcon,
  CloseIcon,
} from "./../../Shared/Icons/Index.js";
import DeleteConfirm from "./../../Shared//DeleteConfirm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RejectProduct from "./rejectProduct";
import { checkifDateLessThan, getRoleEditConstraintDays } from "./../../helper";
import DebitNotePrint from "./../../Shared/DebitNotePrint";
import ReactToPrint from "react-to-print";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Print from "./inwardPrint";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
class Details extends CommonDetails {
  state = {
    value: 0,
    deleteConfirmOpen: false,
    rejectopen: false,
    anchorEl: null,
  };
  deleteRow = null;

  componentDidMount() {
    this.addRejRef = React.createRef();
    this.componentRef = React.createRef();
    this.detailTabRef = React.createRef();
  }

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const data = this.props.data;
    const days = getRoleEditConstraintDays();
    const isUpdateEnable = checkifDateLessThan(data.date, days);
    const hasReject = data.rejectInwardList && data.rejectInwardList.length;
    return (
      <div className="list-section detail-section inward-detail-section">
        <div className="details-header">
          {messages.common.details}
          <div>
            <IconButton
              onClick={(event) =>
                this.setState({ anchorEl: event.currentTarget })
              }
              className="back-icon"
            >
              {MoreIcon({ fontSize: "medium" })}
            </IconButton>
            <Menu
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleCloseMenu}
              classes={{ paper: "detail-dropdown-menu" }}
            >
              <MenuItem onClick={() => this.handleCloseMenu()}>
                <ReactToPrint
                  trigger={() => {
                    return <div>Print</div>;
                  }}
                  content={() => {
                    return this.detailTabRef;
                  }}
                />
              </MenuItem>
              {hasReject ? (
                <MenuItem onClick={() => this.handleCloseMenu()}>
                  <ReactToPrint
                    trigger={() => {
                      return <div>Print Reject</div>;
                    }}
                    content={() => {
                      return this.componentRef;
                    }}
                  />
                </MenuItem>
              ) : null}
              <MenuItem
                disabled={isUpdateEnable}
                onClick={() => {
                  this.handleCloseMenu();
                  this.setState({ rejectopen: true });
                }}
              >
                Add Reject
              </MenuItem>
            </Menu>

            {/* <IconButton
              aria-label="add reject"
              title="Reject Inventory"
              disabled={isUpdateEnable}
              onClick={() => {
                this.setState({ rejectopen: true });
              }}
              className="back-icon"
              innerRef={this.addRejRef}
            >
              <ThumbDownIcon />
            </IconButton> */}
            {/* <Popper
              open={this.state.rejectopen}
              anchorEl={this.addRejRef && this.addRejRef.current}
              placement="bottom-start"
            >
              <RejectProduct
                data={data}
                closeDetails={() => {
                  this.setState({ rejectopen: false });
                  this.props.close(data);
                }}
                goToDetails={() => this.props.goToDetails()}
                close={() => this.setState({ rejectopen: false })}
              />
            </Popper> */}
            {this.state.rejectopen && (
              <RejectProduct
                data={data}
                open={this.state.rejectopen}
                closeDetails={() => {
                  this.setState({ rejectopen: false });
                  this.props.close(data);
                }}
                goToDetails={() => this.props.goToDetails()}
                close={() => this.setState({ rejectopen: false })}
              />
            )}
            <IconButton
              aria-label="back"
              onClick={() => this.props.edit(data)}
              className="back-icon"
              disabled={isUpdateEnable}
            >
              {EditIcon({ fontSize: "medium" })}
            </IconButton>
            <IconButton
              aria-label="back"
              onClick={() => {
                this.deleteRow = data;
                this.setState({ deleteConfirmOpen: true });
              }}
              className="back-icon"
              disabled={isUpdateEnable}
            >
              {DeleteIcon({ fontSize: "medium" })}
            </IconButton>
            <IconButton
              aria-label="back"
              onClick={() => this.props.close(data)}
              className="back-icon"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="print-content">
          <Print ref={(el) => (this.detailTabRef = el)} data={data} />
        </div>
        <div className="debit-note-contetn">
          <DebitNotePrint
            data={data}
            ref={(el) => (this.componentRef = el)}
            inwardReject={true}
          />
        </div>
        <DeleteConfirm
          open={this.state.deleteConfirmOpen}
          onConfirm={() => {
            this.props.delete(this.deleteRow);
            this.setState({ deleteConfirmOpen: false });
          }}
          onCancel={() => {
            this.deleteRow = null;
            this.setState({ deleteConfirmOpen: false });
          }}
        />
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => this.setState({ value: value })}
          value={this.state.value}
        >
          <Tab label="Details" id="simple-tabpanel-0" />
          <Tab label="History" id="simple-tabpanel-1" />
        </Tabs>
        <TabPanel value={this.state.value} index={0}>
          <div className="details-print-content">
            <Paper elevation={0}>
              <div className="details-wrapper">
                <div className="detail-item">
                  <div className="label">{messages.fields.name}</div>
                  <div className="value">{data.supplier.name}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{messages.common.warehouse}</div>
                  <div className="value">{data.warehouse.warehouseName}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{messages.common.receivingDate}</div>
                  <div className="value">{data.date}</div>
                </div>
                <div className="detail-item">
                  <div className="label">
                    {messages.common.purchaseOrderDate}
                  </div>
                  <div className="value">{data.purchaseOrderdate}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{messages.common.purchaseOrderNo}</div>
                  <div className="value">{data.purchaseOrder}</div>
                </div>

                <div className="detail-item">
                  <div className="label">{messages.common.mrngrn}</div>
                  <div className="value">{data.ourSlipNo}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{"Challan No"}</div>
                  <div className="value">{data.challanNo}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{"Challan Date"}</div>
                  <div className="value">{data.challanDate}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{"Bill No"}</div>
                  <div className="value">{data.billNo}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{"Bill Date"}</div>
                  <div className="value">{data.billDate}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{messages.common.vehicleNo}</div>
                  <div className="value">{data.vehicleNo}</div>
                </div>
                <div className="detail-item">
                  <div className="label">{messages.common.comment}</div>
                  <div className="value">{data.additionalInfo}</div>
                </div>
              </div>
              {this.renderFileList(data.fileInformations)}
            </Paper>
            <Paper elevation={0} className="table-wrapper">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{messages.common.inventory}</TableCell>
                    <TableCell>{messages.common.unit}</TableCell>
                    <TableCell>{messages.common.quantity}</TableCell>
                    <TableCell>{messages.common.closingStock}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.inwardOutwardList.map((row) => (
                    <TableRow key={row.productName}>
                       <TableCell>{row.product.productName}</TableCell>
                      <TableCell>{row.product.measurementUnit}</TableCell>
                      <TableCell>{row.quantity}</TableCell>
                      <TableCell>{row.closingStock}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            {hasReject ? (
              <>
                <h4 className="reject-stock">Inward Rejected Stocks</h4>
                <Paper elevation={0} className="table-wrapper">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{messages.fields.date}</TableCell>
                        <TableCell>{messages.common.inventory}</TableCell>
                        <TableCell>{messages.common.unit}</TableCell>
                        <TableCell>{messages.common.oldquantity}</TableCell>
                        <TableCell>
                          {messages.common.returnedQauntity}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.rejectInwardList.map((row) => (
                        <TableRow key={row.productName}>
                          <TableCell>{row.rejectDate}</TableCell>
                          <TableCell>{row.product.productName}</TableCell>
                          <TableCell>{row.product.measurementUnit}</TableCell>
                          <TableCell>{row.oldQuantity}</TableCell>
                          <TableCell>{row.rejectQuantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </>
            ) : null}
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <Paper elevation={0}>
            {data.createdBy && (
              <div className="detail-item">
                <div className="label">Created By</div>
                <div className="value">{data.createdBy}</div>
              </div>
            )}
            {data.creationDate && (
              <div className="detail-item">
                <div className="label">Creation Date</div>
                <div className="value">{data.creationDate}</div>
              </div>
            )}
            {data.inwardOutwardList && data.inwardOutwardList[0] && (
              <div className="detail-item">
                <div className="label">Last Modified By</div>
                <div className="value">
                  {data.inwardOutwardList[0].lastModifiedBy}
                </div>
              </div>
            )}
            {data.inwardOutwardList && data.inwardOutwardList[0] && (
              <div className="detail-item">
                <div className="label">Last Modified On</div>
                <div className="value">
                  {data.inwardOutwardList[0].lastModifiedDate}
                </div>
              </div>
            )}
          </Paper>
        </TabPanel>
      </div>
    );
  }
}

export default Details;
