import React, { Component } from "react";
import { connect } from "react-redux";

import "./printstyle.scss";
class Print extends Component {
  renderData() {
    let data = [];
    data = this.props.data.inwardOutwardList;
    return (
      <>
        <table className="top-table">{this.renderMahavirRow()}</table>
        <table>{this.renderAddRow()}</table>

        <table>
          <tr>
            <th className="no">SR. NO.</th>
            <th className="date">DATE</th>
            <th className="desc">DESCRIPTION</th>
            <th className="quantity">QUANTITY</th>
            <th className="rate">RATE</th>
            <th className="amount">AMOUNT</th>
            <th className="remarks">REMARKS</th>
          </tr>
          {data.map((item, index) => (
            <tr>
              <td className="no">{index + 1}</td>
              <td className="date">{item.creationDate}</td>
              <td className="desc">{item.product.productName}</td>
              <td className="quantity">{item.quantity}</td>
              <td className="rate"></td>
              <td className="amount"></td>
              <td className="remarks">{item.remarks}</td>
            </tr>
          ))}

          <tr>
            <td colSpan={"5"} className="align-right">
              TOTAL
            </td>
            <td className="amount"></td>
            <td className="remarks"></td>
          </tr>
          <tr>
            <td colSpan={"5"} className="align-right">
              Round off
            </td>
            <td className="amount"></td>
            <td className="remarks"></td>
          </tr>
          <tr>
            <td colSpan={"5"} className="align-right">
              NET AMOUNT
            </td>
            <td className="amount"></td>
            <td className="remarks"></td>
          </tr>
        </table>
      </>
    );
  }
  getAddress(client) {
    let address = "";
    if (client.city) {
      address += " " + client.city;
    }
    if (client.state) {
      address += " " + client.state;
    }
    if (client.zip) {
      address += " " + client.zip;
    }
  }

  renderMahavirRow() {
    let type = "";
    let selectenant = this.props.allTenant.filter(
      (t) => t.tenantCode === this.props.tennant
    );
    selectenant = selectenant[0] ? selectenant[0] : this.props.tennant;

    type = "Onward";
    return (
      <>
        <tr>
          <td colSpan="7" className="middle">
            {process.env.REACT_APP_NAME}
          </td>
        </tr>

        <tr>
          <td colSpan="7" className="middle">
            {selectenant.tenantName}
          </td>
        </tr>
        <tr>
          <td colSpan="7" className="middle">
            {type}
          </td>
        </tr>
      </>
    );
  }
  renderAddRow() {
    let client = {},
      row1Text = "",
      row2Text = "",
      row3Text = "",
      row4Text = "",
      row5Text = "",
      row1Value = "",
      row2Value = "",
      row3Value = "",
      row4Value = "",
      row5Value = "";
    const data = this.props.data;
    client = data.contractor;
    row1Text = "Outward date";
    row2Text = "Slip No";
    row3Text = "Building Unit";
    row4Text = "";
    row1Value = data.date;
    row3Value = data.usageLocation ? data.usageLocation.locationName : "";
    row2Value = data.slipNo;
    row4Value = "";

    const add = this.getAddress(client);
    return (
      <>
        <tr>
          <td>Id: {data.outwardid}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td className="width40">Contractor: {client.name}</td>
          <td className="width20"></td>
          <td className="width20">{row1Text}</td>
          <td className="width20">{row1Value}</td>
        </tr>
        <tr>
          <td className="width40">{client.addr_line1}</td>
          <td className="width20"></td>
          <td className="width20">{row2Text}</td>
          <td className="width20">{row2Value}</td>
        </tr>
        <tr>
          <td className="width40">{client.addr_line2}</td>
          <td className="width20"></td>
          <td className="width20">{row3Text}</td>
          <td className="width20">{row3Value}</td>
        </tr>
        <tr>
          <td className="width40">{add}</td>
          <td className="width20"></td>
          <td className="width20">{row4Text}</td>
          <td className="width20">{row4Value}</td>
        </tr>
        <tr>
          <td className="width40"></td>
          <td className="width20"></td>
          <td className="width20">{row5Text}</td>
          <td className="width20">{row5Value}</td>
        </tr>
      </>
    );
  }
  render() {
    const client = this.props.data.supplier || this.props.data.contractor || {};

    return (
      <div className="debit-note" id="debit-note">
        <div className="data">{this.renderData()}</div>
        <div className="footer">
          <div>
            AUTHORISED SIGNATURE <div>{process.env.REACT_APP_NAME}</div>
          </div>
          <div>STORE KEEPER</div>
          <div>Receiver</div>
          <div></div>
          <div className="footer-note4"></div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tennant: state.tennant.tennant_id,
    allTenant: state.allTennant.tennants,
  };
};
export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(Print);
